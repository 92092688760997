import axios, { type AxiosInstance, type AxiosRequestConfig, type AxiosResponse } from "axios";
import { showFailToast } from 'vant';

/** 创建请求实例 */
function createService() {
    const service = axios.create();
    service.interceptors.request.use(
        (config: any) => {
            return config;
        },
        (error: any) => Promise.reject(error),
    );
    service.interceptors.response.use(
        (response: AxiosResponse) => {
            const apiData = response.data as IApiResponseData<any>;
            const code = apiData.result;
            switch (code) {
                case 0:
                    return apiData.data;
                default:
                    showFailToast(apiData.msg)
                    return Promise.reject(apiData.msg || 'error');
            }
        },
        (error: any) => Promise.reject(error),
    );
    return service;
}

/** 创建请求方法 */
function createRequestFunction(service: AxiosInstance) {
    return function <T>(config: AxiosRequestConfig): Promise<T> {
        const configDefault = {
            headers: {
                "Content-Type": 'application/x-www-form-urlencoded',
            },
            baseURL: process.env.VUE_APP_URL,
        }
        return service(Object.assign(configDefault, config));
    }
}

/** 用户网络请求的实例 */
export const service = createService();

/** 用户网络请求的方法 */
export const request = createRequestFunction(service);
