const INTER = [{ space_src: 'baidujs', space_type: 'inters', space_id: 'u6916783', space_js: '//tapbfdh.htinfotech.cn/common/sn/static/m/vwmo-pf.js' }];
const DIALOG = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6916784', space_js: '//tapbfdh.htinfotech.cn/production/t/onw_x/common/npq/k.js' }];
const BANNER = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6916785', space_js: '//tapbfdh.htinfotech.cn/source/upo/static/xyo_q/common/rm.js' }];
const NATIVE = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6916786', space_js: '//tapbfdh.htinfotech.cn/production/v/q/production/p_yz_prsp.js' }];


/** 广告位配置 */
export const SPACE_MAP = {
    Home_inter: INTER,
    Home_dialog: DIALOG,
    Home_banner: BANNER,
    Topic_native: NATIVE,
    Discover_native: NATIVE,
    Hot_native: NATIVE,
    Rank_native: NATIVE,
    TopicDetail_inter: INTER,
    TopicDetail_dialog: DIALOG,
    TopicDetail_banner: BANNER,
    TopicResult_inter: INTER,
    TopicResult_dialog: DIALOG,
    TopicResult_native: NATIVE,
    TopicResult_banner: BANNER,
};
