import { request } from '@/utils/service';

/** 获取互动列表 */
export function getFunplayList(data: FunplayRequestData) {
    return request<ListData<FunplayResponseData>>({
        url: '/api/page/displayList',
        method: 'post',
        data,
    });
}

/** 获取互动详情 */
export function getFunplayDetail(data: FunplayDetailRequestData) {
    return request<FunplaySimpleData>({
        url: '/api/content/detail',
        method: 'post',
        data,
    })
}

/** 获取文章详情 */
export function getArticleDetail(data: ArticleDetailRequestData) {
    return request<ArticleData>({
        url: '/api/article/detail',
        method: 'post',
        data,
    })
}
