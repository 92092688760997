var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ref, reactive } from 'vue';
import { getFunplayList, getFunplayDetail, getArticleDetail } from '@/apis/funplay';
const loading = ref(false);
const funplayHotList = reactive({
    data: []
});
const hotPageConfig = { page: 0, limit: 20 };
const funplayRankList = reactive({
    data: []
});
const rankPageConfig = { page: 0, limit: 20 };
let randomList = [];
const getFunplayData = (type) => {
    return new Promise((resolve) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            let data = {
                page_id: 'home',
                channel: 'oppo-exam',
                rank_name: type,
                sub_page: type == 'hot' ? hotPageConfig.page : rankPageConfig.page,
                sub_limit: type == 'hot' ? hotPageConfig.limit : rankPageConfig.limit,
                screen_width: 375,
                screen_height: 740
            };
            let response = yield getFunplayList(data);
            if (type == 'hot') {
                funplayHotList.data = funplayHotList.data.concat(response.data[0].data);
                if (data.sub_page == 1) {
                    randomList = response.data[0].data.slice().sort((a, b) => Math.random() - 0.5 > 0 ? 1 : -1);
                }
            }
            else {
                funplayRankList.data = funplayRankList.data.concat(response.data[0].data);
            }
            setTimeout(() => {
                loading.value = false;
            }, 500);
            resolve('success');
        }
        catch (error) {
            console.log(error);
            resolve(null);
        }
    }));
};
let funplayDetail = reactive({
    data: {}
});
const getFunplayDetailData = (id) => __awaiter(void 0, void 0, void 0, function* () {
    let params = { id: id, channel: 'oppo-exam' };
    try {
        let response = yield getFunplayDetail(params);
        funplayDetail.data = response;
        setTimeout(() => {
            loading.value = false;
        }, 500);
    }
    catch (error) {
        console.log(error);
    }
});
const articleDetail = reactive({
    data: {}
});
const getArticleDetailData = (id) => __awaiter(void 0, void 0, void 0, function* () {
    let params = { id: id };
    try {
        let response = yield getArticleDetail(params);
        articleDetail.data = response;
        setTimeout(() => {
            loading.value = false;
        }, 500);
    }
    catch (error) {
        console.log(error);
    }
});
const getDailyList = () => {
    let resortRandomList = randomList.slice().sort((a, b) => Math.random() - 0.5 > 0 ? 1 : -1);
    return resortRandomList.slice(0, 4);
};
const getRecommendList = () => {
    return randomList.slice(4, 11);
};
const getNewList = () => {
    return randomList.slice(7, 15);
};
const getExactList = () => {
    return randomList.slice(11, 19);
};
export function useFunplay() {
    return {
        loading,
        funplayHotList,
        funplayRankList,
        hotPageConfig,
        rankPageConfig,
        getFunplayData,
        funplayDetail,
        getFunplayDetailData,
        articleDetail,
        getArticleDetailData,
        getDailyList,
        getRecommendList,
        getNewList,
        getExactList,
    };
}
