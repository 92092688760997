import { ref } from 'vue';

/** 首页插屏是否展示过 */
const HOME_INTER_SHOWN = ref<boolean>(false);

/** 是否进入过互动页 */
const TOPIC_INTER_SHOW = ref<boolean>(false);

export function useAdControl() {
    return { HOME_INTER_SHOWN, TOPIC_INTER_SHOW };
}
